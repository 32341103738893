import { userConstants } from '../_constants';


const initialState = {
    loggedIn: false,
    synchronized: false,
    email: '',
    name: '',
    surname: '',
    role: 'guest',
    status: 'created',
    currency: 'EUR',
    language: 'lv',
    clients: []
};

export function user(state = Object.assign({}, initialState), action: any) {
    switch (action.type) {
        case userConstants.LOAD_SETTINGS_SUCCESS:
            state.loggedIn = true;
            state.synchronized = true;

            return Object.assign({}, {...state, ...action.data, role: action.data.role,
                currentClient: action.data.currentClient});
        case userConstants.LOAD_SETTINGS_FAILURE:
            return Object.assign({...state}, action.data);
        case userConstants.RESET_SETTINGS:
            return Object.assign({}, {...initialState, synchronized: true});
        case userConstants.SWITCH_CLIENT:
            return Object.assign({...state}, {currentClient: action.data.clientId, role: action.data.role});
        default:
            return state;
    }
}
