import React, { useEffect, Component, ErrorInfo } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { LoadingWithSuspense, Error, PrivateRoute } from './components';
import { useDispatch } from 'react-redux';
import { userActions } from './_actions/';
import { useAppSelector} from './_library';
import config from './config';
const PageNotFound = React.lazy(() => import(/* webpackChunkName: "page_not_found" */ './screens/PageNotFound').then(module => ({default: module.PageNotFound})));
const Login = React.lazy(() => import(/* webpackChunkName: "login" */ './screens/Login').then(module => ({default: module.Login})));
const Terms = React.lazy(() => import(/* webpackChunkName: "terms" */ './screens/Terms').then(module => ({default: module.Terms})));
const DeliveryPolicy = React.lazy(() => import(/* webpackChunkName: "delivery_policy" */ './screens/DeliveryPolicy').then(module => ({default: module.DeliveryPolicy})));
const Signup = React.lazy(() => import(/* webpackChunkName: "signup" */ './screens/Signup/Signup').then(module => ({default: module.Signup})));
const PhoneVerification = React.lazy(() => import(/* webpackChunkName: "phone_verification" */ './screens/PhoneVerification').then(module => ({default: module.PhoneVerification})));
const Dashboard = React.lazy(() => import(/* webpackChunkName: "dashboard" */ './screens/Dashboard').then(module => ({default: module.Dashboard})));
const Transactions = React.lazy(() => import(/* webpackChunkName: "transactions" */ './screens/Transactions').then(module => ({default: module.Transactions})));
const Users = React.lazy(() => import(/* webpackChunkName: "users" */ './screens/Users').then(module => ({default: module.Users})));
const User = React.lazy(() => import(/* webpackChunkName: "user" */ './screens/User').then(module => ({default: module.User})));
const Messaging = React.lazy(() => import(/* webpackChunkName: "messaging" */ './screens/Messaging').then(module => ({default: module.Messaging})));
const ConfirmEmail = React.lazy(() => import(/* webpackChunkName: "confirm_email" */ './screens/ConfirmEmail').then(module => ({default: module.ConfirmEmail})));
const PasswordResetRequest = React.lazy(() => import(/* webpackChunkName: "password_reset_request" */ './screens/PasswordResetRequest').then(module => ({default: module.PasswordResetRequest})));
const PasswordReset = React.lazy(() => import(/* webpackChunkName: "password_reset" */ './screens/PasswordReset').then(module => ({default: module.PasswordReset})));
const PhoneSetup = React.lazy(() => import(/* webpackChunkName: "phone_setup" */ './screens/PhoneSetup').then(module => ({default: module.PhoneSetup})));


class ErrorBoundary extends Component {
    constructor(props: {children?: any, [key: string]: any}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(e: any) {
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: ErrorInfo) {
    }

    render() {
        if ((this.props as any).hasError) {
            return <Error mainLabel="bk.placeholder.error.general" buttonLabel="bk.placeholder.error.general.link.home" link="/" />
        }

        return <div className="position-relative">{(this.props as any).children}</div>;
    }
}

type Props = {};

const App: (props: Props) => JSX.Element = () => {

    const dispatch = useDispatch();
    const user = useAppSelector(state => state.user);

    useEffect(() => {
        if (!user.synchronized) {
            (userActions.loadSettings())(dispatch)
        }
    }, []);

    return (
        <ErrorBoundary>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<LoadingWithSuspense Component={Login} />} />
                    <Route path="/login" element={<LoadingWithSuspense Component={Login} />} />
                    <Route path="/terms" element={<LoadingWithSuspense Component={Terms} />} />
                    <Route path="/delivery-policy" element={<LoadingWithSuspense Component={DeliveryPolicy} />} />
                    <Route path="/signup/:step?" element={<LoadingWithSuspense Component={Signup} />} />
                    <Route path="/phone/verify" element={<LoadingWithSuspense Component={PhoneVerification} />} />
                    <Route path="/phone/setup" element={<LoadingWithSuspense Component={PhoneSetup} />} />
                    <Route path="/users/email/confirm/:token" element={<LoadingWithSuspense Component={ConfirmEmail} />} />
                    <Route path="/reset/password/request" element={<LoadingWithSuspense Component={PasswordResetRequest} />} />
                    <Route path="/reset/password/:token" element={<LoadingWithSuspense Component={PasswordReset} />} />
                    <Route path="/dashboard"
                           element={<PrivateRoute Component={Dashboard} minimalRole={config.userRoles['accountant']} />}
                    />
                    <Route path="/transactions"
                           element={<PrivateRoute Component={Transactions} minimalRole={config.userRoles['accountant']} />}
                    />
                    <Route path="/users"
                           element={<PrivateRoute Component={Users} minimalRole={config.userRoles['accountant']} />}
                    />
                    <Route path="/users/:userId?"
                           element={<PrivateRoute Component={User} minimalRole={config.userRoles['accountant']} />}
                    />
                    <Route path="/messaging"
                           element={<PrivateRoute Component={Messaging} minimalRole={config.userRoles['accountant']} />}
                    />
                    <Route path="/404" element={<LoadingWithSuspense Component={PageNotFound} />} />
                    <Route element={<LoadingWithSuspense Component={PageNotFound} />} />
                </Routes>
            </BrowserRouter>
        </ErrorBoundary>
    );
};

export default App;
